import z from 'zod';

import { dateTime, fileInputSchema, toNullIfEmptyString, toNullIfFalsy } from 'src/utils/validation';

export const organizationSchema = z.object({
  name: z.string().trim().nonempty(),
  logo: fileInputSchema.nullish(),
  product_name: z.string().trim().nullish().transform(toNullIfFalsy),
  type: z.string().trim().nonempty(),
  risk_dashboard_access: z.boolean(),
  craft_dashboards_access: z.boolean(),
  craft_dashboards_hosts_transformed: z
    .array(z.object({ value: z.string().trim().nonempty() }))
    .optional()
    .transform((val) => val?.map((item) => item.value)),
  sso_enabled: z.boolean(),
  british_steel: z.boolean(),
  alerts: z.boolean(),

  settings: z.object({
    data: z.object({
      intercomId: z.string().trim().nullish(),
      pdfLogoOverride: fileInputSchema.nullish(),

      craftForTeamsSettings: z.object({
        enabled: z.boolean(),
        trialStartDate: dateTime.or(z.literal('')).nullish().transform(toNullIfEmptyString),
        trialEndDate: dateTime.or(z.literal('')).nullish().transform(toNullIfEmptyString),
      }),

      limits: z.object({
        enabled: z.boolean(),
        portfolioLimit: z.number().int().nonnegative().nullish(),
        portfolioItemsLimit: z.number().int().nonnegative().nullish(),
        userAccountLimit: z.number().int().nonnegative().nullish(),
      }),

      customView: z.object({
        enabled: z.boolean(),
        homePageOverride: z
          .string()
          .trim()
          .url('Must be a valid URL or URL path')
          .or(z.string().trim().regex(/^\/.*/))
          .or(z.literal(''))
          .nullish()
          .transform((val) => {
            if (!val) return null;
            // val is either full URL or valid path
            try {
              // if val is full URL, extract path
              const url = new URL(val);
              return url.pathname === '/' ? '' : url.pathname + url.search;
            } catch (e) {
              return val;
            }
          }),
        homepageTitle: z.string().trim().nullish(),
        homepageMessage: z.string().trim().nullish(),
        homepageProductTitle: z.string().trim().nullish(),
        homepageProductTitleColor: z
          .string()
          .trim()
          .regex(/^#[\dA-F]{6}$/i) // 6-digit hex values only
          .or(z.literal(''))
          .nullish(),
        hideHomeSidebarLink: z.boolean().optional(),
        hideHelpCenterLink: z.boolean().optional(),
        showPoweredByCraft: z.boolean().optional(),
        hideDefaultSearchSuggestions: z.boolean().optional(),
        smallLogoUrl: z.string().trim().url().or(z.literal('')).nullish(),
        customSidebarLinks: z
          .array(
            z.object({
              enabled: z.boolean(),
              url: z.string().trim().url(),
              title: z.string().trim().nonempty(),
              iconUrl: z.string().trim().url(),
            }),
          )
          .nullish(),
      }),

      featureFlags: z.object({
        nativeRiskDashboard: z.boolean(),
        insights: z.boolean(),
        nTier: z.boolean(),
        nTierNew: z.boolean().optional(),
        nTierCentralityScore: z.boolean().optional(),
        nTierImpact: z.boolean().optional(),
        sensitive: z.boolean(),
        firstPartyData: z.boolean(),
        gsca: z.boolean(),
        caseManagementEnabled: z.boolean(),
        caseManagementLocations: z.boolean(),
        internalMailDomains: z
          .string()
          .trim()
          .or(z.array(z.string().trim().nonempty()))
          .nullish()
          .transform((val) => {
            if (!val) return [];
            return Array.isArray(val)
              ? val
              : val
                  .trim()
                  .split(/,\s*|\n/)
                  .filter((s) => !!s);
          }),
        riskCategoryField: z.boolean(),
        riskCategoryChart: z.boolean(),
        companyRequests: z.boolean(),
        advancedSearch: z.boolean(),
        compareCompanies: z.boolean(),
        totpMFAEnabled: z.boolean().optional(),
        customJobBusinessUnitEnabled: z.boolean().optional(),
        maxPortfolioDepth: z
          .number()
          .int()
          .nonnegative()
          .nullish()
          .transform((val) => val ?? undefined),
        portfolioCheckEnabled: z.boolean().optional(),
        brandedEmails: z.boolean().optional(),
      }),

      suggestions: z
        .array(
          z.object({
            label: z.string().trim().nonempty(),
            url: z.string().trim().url(),
          }),
        )
        .nullish(),

      samlSettings: z
        .object({
          certs_transformed: z
            .array(z.object({ value: z.string().trim().nonempty() }))
            .min(1, 'Please add at least 1 cert')
            .transform((val) => val?.map((item) => item.value)),
          issuer: z.string().trim().nonempty(),
          spCerts: z.enum(['current', 'previous']),
          entryPoint: z.string().trim().nonempty(),
          accountName: z
            .string()
            .trim()
            .regex(/^[a-z]{5,12}$/, 'Account Name must be 5-12 characters, and contain only letters'),
        })
        .optional(),

      certaSettings: z
        .object({
          enabled: z.boolean(),
          tenantName: z
            .string()
            .trim()
            .regex(
              /^[a-z]{5,12}$/,
              'Tenant name must be between 5 and 12 characters, and may contain only lowercase letters',
            )
            .or(z.literal(''))
            .nullish(),
        })
        .refine(
          (input) => {
            if (!input) return true;
            return input.enabled ? !!input.tenantName?.length : true;
          },
          {
            path: ['tenantName'],
            message: 'Tenant Name is required when Certa Surveys are enabled',
          },
        ),

      internalOrganizationSettings: z.object({
        showAdminPanel: z.boolean(),
      }),

      securityScoreCardReportDownload: z.object({
        enabled: z.boolean().optional(),
        companySummaryReportEnabled: z.boolean().optional(),
        companyIssueReportEnabled: z.boolean().optional(),
      }),

      notifications_transformed: z
        .record(z.string(), z.boolean())
        .transform((val) => Object.keys(val).map((key) => ({ type: key, enabled: val[key] })))
        .optional(),
    }),
  }),
});

export const createOrganizationSchema = organizationSchema.extend({
  admin_email_address: z.string().trim().email().toLowerCase(),
  subscription: z.object({
    startDate: dateTime.nullish(),
    endDate: dateTime.nullish(),
  }),
});

export const editOrganizationSchema = organizationSchema;
