import { Paper, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { ImageField, ImageInputProps as ImageInputRaProps, ImageInput as ImageInputRA } from 'react-admin';

import WithInfoTooltip from 'src/components/WithInfoTooltip';
import { CommonInputProps } from './types';

const LabelContent = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

interface ImageInputProps extends ImageInputRaProps, CommonInputProps {}

const ImageInput = ({ source, label, infoTooltip, validate, onChange, options, ...rest }: ImageInputProps) => {
  const [dropError, setDropError] = useState<string | null>(null);

  const optionsFinal = { ...options };

  // ImageInput does not handle rejection errors from react-dropzone.
  // Here we collect them into a string for displaying.
  optionsFinal.onDropRejected = (fileRejections, event) => {
    const errorMessage = fileRejections
      .flatMap((rej) => rej.errors)
      .map((err) => err.message)
      .join(' | ');
    setDropError(errorMessage);
    if (options?.onDropRejected) options.onDropRejected(fileRejections, event);
  };

  const handleChange = () => {
    setDropError(null);
    if (onChange) onChange();
  };

  let isRequired = false;
  // This checks for a special field that React-admin sets on the `required` validator.
  if (validate) {
    if (Array.isArray(validate)) {
      isRequired = validate.some((validator) => 'isRequired' in validator);
    } else {
      isRequired = 'isRequired' in validate;
    }
  }

  const labelFinal = `${label || source}${isRequired ? ' *' : ''}`;

  const errorText = dropError && (
    <Typography variant="caption" color="error">
      {dropError}
    </Typography>
  );

  return (
    <>
      {infoTooltip ? (
        <WithInfoTooltip title={infoTooltip} sx={{ mt: 0, ml: 1 }}>
          <LabelContent>{labelFinal}</LabelContent>
        </WithInfoTooltip>
      ) : (
        <LabelContent>{labelFinal}</LabelContent>
      )}

      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
        <ImageInputRA
          {...rest}
          {...(validate && { validate })}
          source={source}
          label={false}
          onChange={handleChange}
          options={optionsFinal}
          helperText={errorText ?? ''}
        >
          <ImageField source="src" title="title" />
        </ImageInputRA>
      </Paper>
    </>
  );
};

export default ImageInput;
