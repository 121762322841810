import { ReactElement } from 'react';
import { Labeled as LabeledRA, LabeledProps as LabeledPropsRA, useTranslateLabel } from 'react-admin';

import WithInfoTooltip from './WithInfoTooltip';

export interface LabeledProps extends LabeledPropsRA {
  tooltip?: ReactElement;
}

export const Labeled = ({ label: labelIn, tooltip, ...rest }: LabeledProps) => {
  const translateLabel = useTranslateLabel();

  const labelText = translateLabel({
    label: labelIn ?? rest.children.props.label,
    source: rest.children.props.source,
  });

  const label = tooltip ? (
    <WithInfoTooltip
      title={tooltip}
      containerProps={{ component: 'span' }}
      sx={{ position: 'relative', top: '0.9em', ml: 1, mt: '-1em' }}
    >
      <span>{labelText}</span>
    </WithInfoTooltip>
  ) : (
    labelText
  );

  return <LabeledRA label={label} {...rest} />;
};

export default Labeled;
