import gql from 'graphql-tag';
import z from 'zod';

import { client } from 'src/data/api';
import { hasValidationErrors } from 'src/utils/validation';
import { organizationSchema } from './validation';

export const uploadOrgScopedLogo = async (
  organizationID: number,
  file: File,
): Promise<{ url: string; validationError?: never } | { url?: never; validationError: string }> => {
  const { data, errors } = await client.query<{
    generateOrganizationLogoUploadUrl: {
      url: string;
    };
  }>({
    query: gql`
      query generateOrganizationLogoUploadUrl($organizationID: Int!, $mimeType: String!) {
        generateOrganizationLogoUploadUrl(organizationID: $organizationID, mimeType: $mimeType) {
          url
        }
      }
    `,
    variables: { organizationID, mimeType: file.type },
    errorPolicy: 'all',
    context: {
      // it's important we disable `queryDeduplication`, as we might upload multiple files in
      // parallel
      queryDeduplication: false,
    },
  });

  if (errors) {
    const error = errors[0];
    if (hasValidationErrors(error)) {
      const validationError = error.extensions.validationErrors.map(({ message }) => message).join('. ');
      return { validationError };
    }

    throw error;
  }

  const fileUploadURL = data.generateOrganizationLogoUploadUrl.url;

  await fetch(fileUploadURL, { method: 'PUT', body: file });

  return { url: fileUploadURL.split('?')[0] };
};

interface FormFileUploadsResult {
  validationErrors?: Partial<Record<string, string>>;
  orgLogoURL: string | null;
  pdfLogoOverrideURL: string | null;
}

export const handleFormFiles = async (
  orgID: number,
  formValues: z.infer<typeof organizationSchema>,
): Promise<FormFileUploadsResult> => {
  const result: FormFileUploadsResult = {
    // default to value's `src`, which holds URL
    orgLogoURL: formValues.logo?.src || null,
    pdfLogoOverrideURL: formValues.settings.data.pdfLogoOverride?.src || null,
  };

  // `rawFile` is newly-added file
  if (!formValues.logo?.rawFile && !formValues.settings.data.pdfLogoOverride?.rawFile) {
    return result;
  }

  const promises: Promise<void>[] = [];
  const validationErrors: Partial<Record<string, string>> = {};

  if (formValues.logo?.rawFile) {
    promises.push(
      uploadOrgScopedLogo(orgID, formValues.logo.rawFile).then((res) => {
        if (res.validationError) validationErrors.logo = res.validationError;
        if (res.url) result.orgLogoURL = res.url;
      }),
    );
  }

  if (formValues.settings.data.pdfLogoOverride?.rawFile) {
    promises.push(
      uploadOrgScopedLogo(orgID, formValues.settings.data.pdfLogoOverride.rawFile).then((res) => {
        if (res.validationError) {
          validationErrors['settings.data.pdfLogoOverride'] = res.validationError;
        }
        if (res.url) result.pdfLogoOverrideURL = res.url;
      }),
    );
  }

  await Promise.all(promises);

  if (Object.keys(validationErrors).length) result.validationErrors = validationErrors;
  return result;
};

export const createOrganization = (orgParams: Record<string, unknown>) =>
  client.mutate<{
    createOrganization: {
      id: number;
    };
  }>({
    mutation: gql`
      mutation createOrganization($organization: CreateOrganizationInput!) {
        createOrganization(organization: $organization) {
          id
        }
      }
    `,
    variables: { organization: orgParams },
    errorPolicy: 'all',
  });

export const updateOrganization = (orgID: number, orgParams: Record<string, unknown>) =>
  client.mutate<{
    updateOrganization: boolean;
  }>({
    mutation: gql`
      mutation updateOrganization($id: Int!, $organization: UpdateOrganizationInput!) {
        updateOrganization(id: $id, organization: $organization)
      }
    `,
    variables: { id: orgID, organization: orgParams },
    errorPolicy: 'all',
  });
