import z, { ZodIssue } from 'zod';
import { set } from 'lodash';
import { GraphQLError } from 'graphql';

type ValidationErrors = ZodIssue[];

export const hasValidationErrors = (
  error: GraphQLError,
): error is GraphQLError & { extensions: { validationErrors: ValidationErrors } } =>
  'validationErrors' in error.extensions;

/**
 * Converts ZodIssue array into a map of field-errorMessage values. Useful for form validation.
 */
export const zodIssuesIntoErrorsMap = (issues: ZodIssue[]) =>
  issues.reduce((acc, issue) => {
    set(acc, issue.path, issue.message);
    return acc;
  }, {} as Record<string, unknown>);

/**
 * Tries to read and parse validation errors in a `GraphQLError`'s extensions. If successful,
 * returns the extracted validation errors as a field-errorMessage map. Otherwise, returns null.
 */
export const parseResponseValidationErrors = (error: GraphQLError) =>
  hasValidationErrors(error) ? zodIssuesIntoErrorsMap(error.extensions.validationErrors) : null;

export const dateTime = z.string().transform((val, ctx) => {
  const date = new Date(val);

  if (Number.isNaN(date.getTime())) {
    ctx.addIssue({ code: z.ZodIssueCode.invalid_date });
    return z.NEVER;
  }

  return date.toISOString();
});

export const toNullIfFalsy = <T>(val: T) => val || null;
export const toNullIfEmptyString = <T>(val: T) => (val === '' ? null : val);

export const fileInputSchema = z.object({
  rawFile: z.instanceof(File).optional(),
  src: z.string().trim().url().nullable(),
  title: z.string().trim().optional(),
});
